import { template as template_dd218d7666ef4c07ac32d975c9a92978 } from "@ember/template-compiler";
const FKLabel = template_dd218d7666ef4c07ac32d975c9a92978(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
