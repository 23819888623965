import { template as template_8ee24da44cfe40f9b055b2b046e825f5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_8ee24da44cfe40f9b055b2b046e825f5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
