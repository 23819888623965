import { template as template_b08f4741e7fa433293e3027b277627ad } from "@ember/template-compiler";
const FKText = template_b08f4741e7fa433293e3027b277627ad(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
